body {
  margin: 0;
  font-family: 'Roboto Condensed',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #8cb700;
  text-decoration: none;
}

.p-autocomplete .p-button,
.p-selectbutton .p-button.p-highlight {
  background-color: #8cb700 !important;
}

.green-button:not(.p-button-outlined),
.p-inputnumber-button-group .p-button,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-slider .p-slider-range,
.p-radiobutton .p-radiobutton-box.p-highlight,
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #8cb700 !important;
  border-color: #8cb700 !important;
}
.green-button.p-button-outlined {
  background: transparent !important;
}


.yellow-button:not(.p-button-outlined) {
  background: #FFECB3 !important;
  color: #6d5100 !important;
  border: 1px solid #6d5100;
}
.yellow-button.p-button-outlined {
  background: transparent !important;
}

.p-slider .p-slider-handle {
  border: 3px solid #8cb700 !important;
}

.p-button.p-button-text,
.p-button.p-button-link {
  color: #8cb700 !important;
}

.p-progress-spinner-circle {
  stroke: #8cb700 !important;
  animation: none  !important;
}

::selection {
  background: #c0e34d !important;
}
::moz-selection {
  background: #c0e34d !important;
}
.p-button.p-button-outlined,
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: #8cb700 !important;
  color: #8cb700 !important;
}
.p-inputtext:enabled:focus {
  border-color: #8cb700 !important;
}

.grid-container {
  display:flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}
.mobile-row {
  display: flex;
  flex-direction: row;
}
.form-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}
.btn {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.link-credit {
  font-size: 0.75em;
}
.img-subtitle {
  opacity: 0.8;
  display: block;
}
.p-button.p-button-link:enabled:focus,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #cde0b7 !important;
}

.p-megamenu-root-list .p-menuitem-link {
  border-radius: 2rem !important;
}

.link-underline:hover, .link-underline:focus {
  text-decoration: underline;
}

.side {
  border-right: 1px solid #ddd;
}

textarea { resize: none; }

@media only screen and (max-width: 575px) {
  .mobile-row {
    flex-direction: column;
  }
  .img-subtitle {
    font-size: 0.9em;
  }
  .side {
    border-right: none;
  }

}

.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}

.p-inputtext.p-variant-filled {
  background-color: #f3f4f6;
  cursor: copy;
}
.p-inputtext.p-variant-filled:enabled:hover {
  background-color: #f3f4f6;
}

.EmojiPickerReact {
  position: absolute !important;
  top: calc(50% - 200px) !important;
  left: calc(50% - 178px) !important;
}

.fade-bg {
  background: #333;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}