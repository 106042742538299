.bug-form .label {
    display: inline-block;
    margin-bottom: 16px;
    margin-right: 16px;
}

.radio-group label {
    margin-right: 32px;
}
.radio-group {
    flex-direction: row;
    display: flex;
}
#title::placeholder {
    color: #aaa;
}
@media only screen and (max-width: 575px) {
    .radio-group {
        display: block;
    }
    .radio-container {
        display: block;
    }
    .radio-group {
        flex-direction: column;
    }
    .col-12-override {
        width: 100% !important;
    }
}