.App {
  text-align: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-component {
  font-family: 'Inclusive Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}
.p-sidebar-right .p-sidebar {
  width: 50rem !important;
}

.p-panel .p-panel-header {
    padding: 0.5rem 1rem !important;
}

.stats-tabmenu .p-tabmenuitem {
  width: 50%;
}
.stats-tabmenu .p-menuitem-link {
  justify-content: center;
}
.coming-soon {
  font-size: 0.8em;
  opacity: 0.8;
  margin-left: 8px;
}
.home-gallery {
  max-width: 100%;
  height: 40vh;
  overflow: hidden;
}
.home-gallery img {
  height: 30vh;
  width: auto;
  border-radius: 5px;
}
.home-gallery .p-galleria-item,
.home-gallery .p-galleria-item-container  {
  justify-content: flex-end !important;
}

.img-container {
  background-color: transparent !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  display: flex !important;
  position: relative !important;
  overflow: hidden;
  border: none !important;
  justify-content: flex-end;
  align-items: flex-start !important;
}
.img-container:hover {
  cursor: zoom-in;
}
.p-button-group .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.p-button-group .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;

}
.p-button-group .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

.btn-group button {
  width: 33%;
}
.emoji-btn svg {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
}

.emoji-btn .p-button-label {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  font-size: 1.2em !important;
  line-height: 0;
  left: 25%;
  top: 50%;
}

.p-calendar .p-datepicker {
  padding: 0;
  max-height: 100px;
  overflow: hidden;
}

.p-timepicker {
  padding: 0;
  height: 100%;
}

.p-datepicker .p-timepicker button {
  height: 1.5rem !important;
}

.p-datepicker-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.p-datepicker-title select {
  width: 35%;
  font-size: 1.1em;
}

.hidden-time span,
.hidden-time button {
  color: transparent;
}

.hidden-time svg {
  opacity: 0;
}

.datetime-container {
  overflow: hidden;
  transition: height 1s ease-in-out;
}

.img-count {
  float: right !important;
  background: rgba(0, 0, 0, 0.6) !important;
  user-select: none  !important;
  margin-top: 16px !important;
  margin-right: 16px !important;
  font-size: 1.2em !important;
  padding: 12px !important;
  box-sizing: content-box;
  color: #eee !important;
  display: flex !important;
  height: auto !important;
  border-radius: 100px  !important;
  font-weight: 500 !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}

.img-button {
  margin-top: 12px !important;
  height: 96% !important;
  background: none !important;
  border: none !important;
}
.img-button .p-button-icon {
  background: rgba(0, 0, 0, 0.6) !important;
  border-radius: 100px !important;
  padding: 10px !important;
  color: #eee !important;
  transition: all 0.4s ease-in-out !important;
  font-size: 1.25em  !important;
}
.img-button:hover .p-button-icon,
.img-button:active .p-button-icon,
.img-button:focus .p-button-icon {
  background: rgba rgba(0, 0, 0, 0.8) !important;
  transition: all 0.4s ease-in-out !important;
}
