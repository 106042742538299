
.homepage-img-container {
    padding-left: 32px;
}
@media only screen and (max-width: 575px) {
    .list {
        margin: 0 !important;
    }
    .list li a {
        display: block;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
}