
.footer {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
    color: #495057;
    width: 100%;
    padding: 14px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-links a {
    padding: 10px
}