.p-galleria-item {
    width: 100% !important;
    max-height: calc(80vh - 124px) !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    overflow: hidden;
    border-radius: 4px;
}
.gallery-image {
    width: 100%;
    /* max-width: 50vw; */
}
.gallery-image img {
    width: 100%;
}

.p-autocomplete,
.p-autocomplete-input {
    width: 100%;
}

.capitalize {
    text-transform: capitalize;
    font-weight: bold;
}

.p-selectbutton .p-button {
    width: 33%;
    justify-content: center;
}
.p-buttonset .p-button:not(:last-child) {
    border-right: 1px solid white !important;
}

.level-autocomplete .p-disabled {
    opacity: 1 !important;
    user-select: none;

}
.p-autocomplete,
.p-autocomplete ul {
    width: 100%;
}

.p-buttonset .p-button:last-of-type {
    border-radius: 0 2rem 2rem 0 !important;
}
.p-buttonset .p-button:first-of-type {
    border-radius: 2rem 0 0 2rem  !important;
}
.level-autocomplete-panel .p-autocomplete-item {
    padding: 0 !important;
}
.fw-bold {
    font-weight: bold;
}
.how-to-play {
    margin: 0;
}
.how-to-play li {
    margin-bottom: 12px;
}
.kingdom-select-container .p-button {
    font-size: 0.8em;
}

.level-status {
    width: 50px !important;
    height: 50px;
}
.responsive-modal {
    width: 60vw;
}
.time-toggle-btn {
    cursor: pointer;
    margin-bottom: -10px !important;
    margin-right: 16px;
}
.time-toggle-btn.timer-shown {
    background: white !important;
    border-color: #8cb700 !important;
    color: #8cb700 !important;
}
.time-toggle-btn.timer-shown .p-button-icon-left {
    color: #8cb700 !important;
}
.game-end-img {
    border-radius: 4px;
}
.responsive-modal .p-dialog-header {
    padding: 16px !important;
}
.level-container {
    margin-bottom: 10px;
}
.guess-container {
    padding: 16px;
}
.p-autocomplete-items {
    width: auto;
}
.p-dialog-content {
    padding-top: 16px;
}
.p-panel-header {
    flex-direction: row;
}
.kingdom-icon {
    margin-right: 8px;
}

@media only screen and (max-width: 575px) {
    .level-status {
        margin-top: 11px;
        width: 28px !important;
        height: 28px !important;
    }
    .responsive-modal {
        width: 100vw !important;
    }
    .game-end-img {
        max-width: 100%;
    }
    .level-autocomplete .p-inputtext {
        font-size: 0.8em;
    }
    .level-container .p-button.p-button-icon-only {
        width: 1.75rem;
    }
    .capitalize {
        font-size: 0.8em;
    }
    .guess-container {
        padding: 6px;
    }
    .p-autocomplete-items {
        width: 100vw;
    }
    .guess-btn {
        margin-bottom: 40px;
    }
    .p-panel-header.practice-settings {
        flex-direction: column;
        padding: 0 !important;
    }
    .panel-title {
        margin-bottom: 16px;
        padding-left: 0;
        padding-right: 0;
    }
    .practice-presets {
        width: 100%;
    }
    .kingdom-icon {
        margin-right: 2px;
    }
    .pref-button span {
        font-size: 0.9em;
    }
}