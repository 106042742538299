.location-container,
.radius {
    margin-top: 12px;
    padding: 8px;
    display: flex;
    width: 50%;
    align-items: center;
}
.location-container {
    justify-content: space-between;
}
.radius {
    justify-content: flex-end;
}

.lat, .lng {
    display: flex;
    align-items: center;
}
.lat {
    width: 45%;
}
.lng {
    width: 55%;
}

.lat .p-inputwrapper, .lng .p-inputwrapper {
    width: 100%;
}

.lat-lng-rad {
    padding: 6px;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.lat strong {
    padding-right: 12px;
}
.lng strong {
    padding: 0 12px;
    padding-left: 32px;
}
@media only screen and (max-width: 1025px) {
    .location-container {
        width: 100%;
    }
    .lat-lng-rad {
        width: 100%;
        flex-direction: column;
    }
    .lat, .lng {
        width: 50%;
    }
    .radius {
        justify-content: flex-start;
    }
}
@media only screen and (max-width: 575px) {
    .location-container {
        flex-direction: column;
        justify-content: flex-end;
    }
    .lat-lng-rad {
        flex-direction: column;
    }
    .lat, .lng {
        width: 100%;
    }
    .lat strong,
    .lng strong {
        padding: 0;
        width: 33%;
    }
}